import React, { Component } from 'react';

import ProductsMenuItem from './ProductsMenuItem';

class ProductsMenu extends Component {
  render() {
    const { menu, pageId, site } = this.props;

    const iterateMenu = (activeChain, current) => {
      if (current.linkTarget?.id !== pageId && current.children) {
        for (const childItem of current.children) {
          if (
            iterateMenu(activeChain, childItem) === true ||
            activeChain.length > 0
          ) {
            activeChain.push(childItem.linkTarget?.id);
            break;
          }
        }
      }
      return current.linkTarget?.id === pageId ? true : activeChain;
    };
    const activeMenuItems = iterateMenu([], { children: menu });

    return (
      <nav className="menu">
        <ul className="menu-list">
          {menu.map((menuItem) => (
            <ProductsMenuItem
              key={menuItem.id}
              activeMenuItems={activeMenuItems}
              menuItem={menuItem}
              pageId={pageId}
              site={site}
            />
          ))}
        </ul>
      </nav>
    );
  }
}

export default ProductsMenu;
