import React, { Component } from 'react';

import Link from '../../../Link';
import { AngleDownFat } from '../../../_common/Icons';
import { getCmsValue } from '../../../../utils/utils';

class ProductsMenuItem extends Component {
  constructor(props) {
    super(props);

    this.toggleExpanded = this.toggleExpanded.bind(this);

    this.initialState = {
      active: false,
      expanded: false,
    };

    this.state = { ...this.initialState };
  }

  componentDidMount() {
    const { activeMenuItems, menuItem } = this.props;

    if (activeMenuItems && activeMenuItems.includes(menuItem.linkTarget?.id)) {
      this.setState((prevState) => ({
        ...prevState,
        active: true,
        expanded: true,
      }));
    }
  }

  componentDidUpdate(prevProps) {
    const { activeMenuItems, menuItem, pageId } = this.props;

    if (prevProps.pageId !== pageId) {
      if (
        activeMenuItems &&
        activeMenuItems.includes(menuItem.linkTarget?.id)
      ) {
        this.setState((prevState) => ({
          ...prevState,
          active: true,
          expanded: true,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          active: false,
          expanded: false,
        }));
      }
    }
  }

  toggleExpanded() {
    this.setState((prevState) => ({
      ...prevState,
      expanded: !prevState.expanded,
    }));
  }

  render() {
    const { activeMenuItems, menuItem, pageId, site } = this.props;

    const subMenuItems = menuItem.children.map((childItem) => (
      <ProductsMenuItem
        key={childItem.id}
        activeMenuItems={activeMenuItems}
        menuItem={childItem}
        pageId={pageId}
        site={site}
      />
    ));

    return (
      <li
        className={`menu-list-item ${
          this.state.expanded ? 'menu-list-item--expanded' : ''
        }`}
      >
        <div className="menu-list-item__header">
          <Link
            className={`menu-list-item__header__link ${
              this.state.active ? 'menu-list-item__header__link--active' : ''
            }`}
            site={site}
            to={menuItem?.linkTarget}
          >
            {getCmsValue(menuItem?.title, site)}
          </Link>

          {menuItem.children.length > 0 && (
            <span
              className="menu-list-item__header__expand"
              onClick={this.toggleExpanded}
            >
              {AngleDownFat}
            </span>
          )}
        </div>
        <ul className="menu-list">{subMenuItems}</ul>
      </li>
    );
  }
}

export default ProductsMenuItem;
