import { connect } from 'react-redux';
import React, { Component } from 'react';
import Select from '../../../_common/Select';

import { setSortKey } from '../../../../state/filterService';

class ProductsSort extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const { dispatch } = this.props;
    dispatch(setSortKey(e.value));
  }

  render() {
    const { sortKey } = this.props;

    const options = [
      { label: 'Best Sellers', value: 'quantitySold' },
      { label: 'Top rated', value: 'score' },
      { label: 'Price: Low to High', value: 'priceLow' },
      { label: 'Price: High to Low', value: 'priceHigh' },
      { label: 'New to Old', value: 'newest' },
      { label: 'Old to New', value: 'oldest' },
      { label: 'Product Name', value: 'name' },
      { label: 'Product Series', value: 'series' },
    ];

    return (
      <div className="sort">
        <Select
          options={options}
          onChange={this.onChange}
          value={options.find((option) => option.value === sortKey)}
        />
      </div>
    );
  }
}

export default connect((state) => ({
  sortKey: state.filterService.sortKey,
}))(ProductsSort);
