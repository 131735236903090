import Helmet from 'react-helmet';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import JsonLD from '../../_common/JsonLD';
import ProductsList from './ProductsList';
import ProductsSidebar from './sidebar/ProductsSidebar';
import ProductsFilters from './sidebar/ProductsFilters';
import ProductsMenu from './sidebar/ProductsMenu';
import ProductsSearch from './sidebar/ProductsSearch';
import ProductsSort from './sidebar/ProductsSort';
import withMenus from '../../../containers/withMenus';
import withSite from '../../../containers/withSite';
import { Close, Filter } from '../../_common/Icons';
import { getCmsValue } from '../../../utils/utils';

class ProductsFiltering extends Component {
  constructor(props) {
    super(props);

    this.onToggleFilters = this.onToggleFilters.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);

    this.initialState = {
      filtersOpen: false,
      menuOpen: false,
    };

    this.state = { ...this.initialState };
  }

  onToggleFilters(open) {
    this.setState((prevState) => ({
      ...prevState,
      filtersOpen: open,
    }));
  }

  onToggleMenu(open) {
    this.setState((prevState) => ({
      ...prevState,
      menuOpen: open,
    }));
  }

  render() {
    const { menus, pageId, productGroup, products, jsonLd, site } = this.props;
    const { filtersOpen, menuOpen } = this.state;

    const closeFilterSection = (cb) => (
      <span className="filtering-section__close" onClick={() => cb(false)}>
        {Close} Close
      </span>
    );

    return (
      <div className="products__filtering">
        <div className="filtering-toggles">
          <button
            className="filtering-toggle"
            onClick={() => this.onToggleFilters(true)}
          >
            {Filter}Filter
          </button>
          <button
            className="filtering-toggle"
            onClick={() => this.onToggleMenu(true)}
          >
            Categories
          </button>
        </div>

        <div className="filtering-sections">
          <div
            className={`filtering-section filtering-section__filters ${
              filtersOpen ? 'filtering-section__filters--open' : ''
            }`}
          >
            <div className="filtering-section__actions">
              {closeFilterSection(this.onToggleFilters)}
            </div>

            <div className="filtering-section__filter-wrapper">
              <span>Filter on:</span>
              <ProductsFilters filteredProducts={products} site={site} />
            </div>
            <div className="filtering-section__filter-wrapper">
              <span>Sort:</span>
              <ProductsSort />
            </div>
          </div>

          <div
            className={`filtering-section filtering-section__menu ${
              menuOpen ? 'filtering-section__menu--open' : ''
            }`}
          >
            <div className="filtering-section__actions">
              {closeFilterSection(this.onToggleMenu)}
            </div>

            <div className="filtering-section__filter-wrapper">
              <ProductsMenu
                menu={menus.productMenu.children}
                pageId={pageId}
                site={site}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withSite,
  withMenus,
  connect((state) => ({
    filterServiceData: { ...state.filterService },
  }))
)(ProductsFiltering);
