import { compose } from 'redux';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import withKlaviyo from '../../../../containers/plugins/withKlaviyo';

import { Search } from '../../../_common/Icons';
import { setSearchTerm } from '../../../../state/filterService';

class ProductsSearch extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);

    this.timeout = null;
  }

  onChange(e) {
    const { dispatch, klaviyo, location } = this.props;
    dispatch(setSearchTerm(e.target.value));

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      klaviyo.searchedFor(`Products ${location}`, e.target.value);
    }, 3000);
  }

  render() {
    const { location, searchTerm } = this.props;

    return (
      <div
        className={`products-search products-search--${location.toLowerCase()}`}
      >
        <input
          type="text"
          placeholder="What are you looking for?"
          onChange={this.onChange}
          value={searchTerm}
        />
        {Search}
      </div>
    );
  }
}

export default compose(
  withKlaviyo,
  connect((state) => ({
    searchTerm: state.filterService.searchTerm,
  }))
)(ProductsSearch);
