import Helmet from 'react-helmet';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import Breadcrumbs from '../../breadcrumbs/Breadcrumbs';
import JsonLD from '../../_common/JsonLD';
import ProductGiftCard from '../../../models/ProductGiftCard';
import ProductsFiltering from './ProductsFiltering';
import ProductsList from './ProductsList';
import ProductsSidebar from './sidebar/ProductsSidebar';
import ProductsSearch from './sidebar/ProductsSearch';
import Slice from '../../slices/Slice';
import withMenus from '../../../containers/withMenus';
import withSite from '../../../containers/withSite';
import { Close, Filter } from '../../_common/Icons';
import { getCmsValue } from '../../../utils/utils';

class Products extends Component {
  constructor(props) {
    super(props);

    this.onSidebarReady = this.onSidebarReady.bind(this);
    this.onToggleSidebar = this.onToggleSidebar.bind(this);
    this.onToggleFilters = this.onToggleFilters.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);

    this.initialState = {
      filtersOpen: false,
      menuOpen: false,
      sidebarOpen: false,
      sidebarWrapperRef: null,
    };

    this.state = { ...this.initialState };
  }

  onSidebarReady(sidebarWrapperRef) {
    this.setState((prevState) => ({
      ...prevState,
      sidebarWrapperRef: sidebarWrapperRef,
    }));
  }

  onToggleSidebar() {
    this.setState(
      (prevState) => ({
        ...prevState,
        sidebarOpen: true,
      }),
      () => {
        if (this.state.sidebarOpen) {
          const top = this.state.sidebarWrapperRef.getBoundingClientRect().top;
          window.scrollBy({
            left: 0,
            top: top - (96 + 20),
            behavior: 'smooth',
          });
        }
      }
    );
  }

  onToggleFilters(open) {
    this.setState((prevState) => ({
      ...prevState,
      filtersOpen: open,
    }));
  }

  onToggleMenu(open) {
    this.setState((prevState) => ({
      ...prevState,
      menuOpen: open,
    }));
  }

  render() {
    const { breadcrumbs, menus, pageId, productGroup, products, jsonLd, site } =
      this.props;

    const giftCartCategories = ['all', 'accessories'];

    if (giftCartCategories.includes(productGroup.path)) {
      if (!products.find((p) => p.path === 'gift-card')) {
        const giftCard = new ProductGiftCard(site.siteUID, site);
        products.push(giftCard);
      }
    }

    return (
      <>
        <ProductsSearch location="Header" />

        <article className="page products">
          <Helmet title={getCmsValue(productGroup.title, site)} />
          <JsonLD jsonLd={jsonLd} />
          <Breadcrumbs breadcrumbs={breadcrumbs} site={site} />

          <div className="products__content">
            <aside className="products__sidebar">
              <ProductsSidebar
                onSidebarReady={this.onSidebarReady}
                pageId={pageId}
                productMenu={menus.productMenu?.children}
                products={products}
                sidebarOpen={this.state.sidebarOpen}
                site={site}
              />
            </aside>

            <div className="products__main">
              <header className="products__header">
                <h1 className="products__title">
                  {getCmsValue(productGroup.title, site)}
                </h1>
                <p className="products__ingress">
                  {getCmsValue(productGroup.ingress, site)}
                </p>

                {productGroup.mediaSlice?.media?.src && (
                  <Slice slice={productGroup.mediaSlice} />
                )}

                <ProductsFiltering {...this.props} />
              </header>

              <ProductsList
                id={pageId}
                productGroup={productGroup}
                products={products}
                site={site}
              />
            </div>
          </div>
        </article>
      </>
    );
  }
}

export default compose(
  withSite,
  withMenus,
  connect((state) => ({
    filterServiceData: { ...state.filterService },
  }))
)(Products);
