import { graphql } from 'gatsby';
import React, { Component } from 'react';

import breadcrumbs from './breadcrumbs';
import jsonLd from './schemas';
import ProductFragment from './fragments/Product';
import ProductModel from '../models/Product';
import ProductGroupModel from '../models/ProductGroup';
import ProductsComponent from '../components/products/products/Products';
import { mapEdgesToNodes } from '../utils/utils';

export const query = graphql`
  query (
    $category: String!
    $channelProducts: [Int]
    $id: String!
    $productBrands: [String]!
    $subCategory: String!
  ) {
    sanityProductGroup(id: { eq: $id }) {
      category {
        value
      }
      collection {
        value
      }
      featuredMedia {
        image {
          alt {
            languagePrimary
            languageSecondary
          }
          asset {
            gatsbyImageData
          }
        }
        type
        video {
          value
        }
        videoType
        videoYoutube
      }
      ingress {
        languagePrimary
        languageSecondary
      }
      productGroupType
      subCategory {
        value
      }
      title {
        languagePrimary
        languageSecondary
      }
      uid {
        current
      }
    }
    allInRiverProduct(
      filter: {
        entityId: { in: $channelProducts }
        ProductBrand: { value: { value: { in: $productBrands } } }
        ProductCategory: { value: { key: { glob: $category } } }
        ProductSubCategory: { value: { key: { glob: $subCategory } } }
      }
    ) {
      edges {
        node {
          ...Product
        }
      }
    }
  }
`;

class Products extends Component {
  render() {
    const { brand, menus, site, path } = this.props;

    const { id, siteUID } = this.props.pageContext;
    const { sanityProductGroup, allInRiverProduct } = this.props.data;

    const productGroup = new ProductGroupModel(sanityProductGroup, siteUID);
    const products = mapEdgesToNodes(allInRiverProduct).map(
      (product) => new ProductModel(product, siteUID, site)
    );

    return (
      <ProductsComponent
        brand={brand}
        breadcrumbs={breadcrumbs('PRODUCTS', productGroup, menus)}
        pageId={id}
        productGroup={productGroup}
        products={products}
        siteUID={siteUID}
        jsonLd={jsonLd(brand, site, path, productGroup)}
        site={site}
      />
    );
  }
}

export default Products;
