import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ProductsFilters from './ProductsFilters';
import ProductsMenu from './ProductsMenu';
import ProductsSearch from './ProductsSearch';
import ProductsSort from './ProductsSort';
import { filterProducts, getCmsValue } from '../../../../utils/utils';

class ProductsSidebar extends Component {
  render() {
    const {
      filterServiceData,
      onSidebarReady,
      pageId,
      productMenu,
      sidebarOpen,
      site,
    } = this.props;

    const products = filterProducts(
      filterServiceData,
      this.props.products,
      site
    );

    return (
      <>
        <h3 className="sidebar__section-title">
          {getCmsValue(site.strings.productGroup__sidebarSearchTitle, site)}
        </h3>

        <ProductsSearch location="Sidebar" />

        <div
          className={`sidebar__wrapper ${
            sidebarOpen ? 'sidebar__wrapper--open' : ''
          }`}
          ref={(ref) => onSidebarReady(ref)}
        >
          {productMenu && (
            <>
              <h3 className="sidebar__section-title">
                {getCmsValue(
                  site.strings.productGroup__sidebarCategoriesTitle,
                  site
                )}
              </h3>
              <ProductsMenu menu={productMenu} pageId={pageId} site={site} />
            </>
          )}
        </div>
      </>
    );
  }
}

export default compose(
  connect((state) => ({
    filterServiceData: { ...state.filterService },
  }))
)(ProductsSidebar);
