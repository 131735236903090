import { connect } from 'react-redux';
import React, { Component } from 'react';

import ProductsFilterItem from './ProductsFilterItem';
import { getPimValue } from '../../../../utils/utils';
import { setSortKey } from '../../../../state/filterService';

class ProductsFilters extends Component {
  render() {
    const { dispatch, filteredProducts, filters, site } = this.props;

    return (
      <div className="filters">
        {Object.keys(filters)
          .filter((filterKey) =>
            filteredProducts.find((product) => {
              if (product[filterKey]) {
                if (Array.isArray(product[filterKey])) {
                  return getPimValue(product[filterKey][0]);
                } else {
                  return getPimValue(product[filterKey]);
                }
              }
            })
          )
          .map((filterKey) => (
            <ProductsFilterItem
              key={filterKey}
              dispatch={dispatch}
              filter={filters[filterKey]}
              filterKey={filterKey}
              filteredProducts={filteredProducts}
              site={site}
            />
          ))}
      </div>
    );
  }
}

export default connect((state) => ({
  filters: { ...state.filterService.filters },
}))(ProductsFilters);
