import { graphql, StaticQuery } from 'gatsby';
import React, { Component } from 'react';

import { mapEdgesToNodes } from '../utils/utils';

import Site from '../models/Site';

export const query = graphql`
  {
    allSanitySite {
      edges {
        node {
          countryCode
          id
          inventoryField {
            value
          }
          languagePrimary {
            title
            value
          }
          languageSecondary {
            title
            value
          }
          originalPriceField {
            value
          }
          parentSite {
            uid {
              current
            }
          }
          shopEnabled
          shopUrl
          title
          type
          uid {
            current
          }
        }
      }
    }
  }
`;

function withSite(WrappedComponent) {
  class WithSite extends Component {
    constructor(props) {
      super(props);

      this.renderer = this.renderer.bind(this);
    }

    renderer(data) {
      const { siteUID } = this.props;
      const rawSites = mapEdgesToNodes(data.allSanitySite);

      const sites = rawSites.map((rawSite) => new Site(rawSite));
      const site = sites.find((site) => site.siteUID === siteUID);

      return <WrappedComponent sites={sites} site={site} {...this.props} />;
    }

    render() {
      return <StaticQuery query={query} render={this.renderer} />;
    }
  }

  return WithSite;
}

export default withSite;
